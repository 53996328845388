import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

// Images
import IconImg1 from "../../images/icon/contact/icon1.png"
import IconImg2 from "../../images/icon/contact/icon2.png"
import IconImg3 from "../../images/icon/contact/icon3.png"
import Logo from "../../images/logo.png"

class ContactFooter extends Component{

	constructor(props) {
        super(props);
        this.state = {
            formSubmitted: false // Estado para controlar si el formulario ha sido enviado
        };
    }
	
	componentDidMount() {
		
		// Contact Sidebar
        var asideBtn = document.querySelector(".contactFooter")
        var contactSidebar = document.querySelector(".contact-sidebar")
        var closeBtn = document.getElementById("clase-btn")

        asideBtn.addEventListener('click',function(){
            contactSidebar.classList.add("open")
        })

        closeBtn.addEventListener('click',function(){
            contactSidebar.classList.remove("open")
        })        
    }

	handleSubmit = async (event) => {
        event.preventDefault(); // Previene el comportamiento por defecto del formulario

        // Captura los valores de los campos del formulario
        const name = event.target.name.value;
        const email = event.target.email.value;
        const message = event.target.message.value;

        // Crea un objeto JSON con los datos del formulario
        const formData = {
			data:{
				nombre: name,
				email: email,
				mensaje: message
			}            
        };

        try {
            // Realiza la solicitud POST al endpoint
            const response = await axios.post('http://3.82.101.137:1337/api/contact-forms', formData);

            // Lógica adicional después de enviar el formulario (por ejemplo, mostrar un mensaje de éxito)
            //console.log('Formulario enviado exitosamente', response.data);
			this.setState({ formSubmitted: true });
        } catch (error) {
            // Manejo de errores en caso de que la solicitud falle
            console.error('Error al enviar el formulario', error);
        }
    }
	
	render(){
		return(
			<>
				<div className="contactFooter">
					{/* <ul className="slide-social">
						<li>Redes sociales</li>
						<li className="sc-link"><a href={"https://twitter.com/ScienceVico"}><i className="fa fa-twitter"></i></a></li>
						<li className="sc-link"><a href={"https://www.linkedin.com/company/vico-science/"}><i className="fa fa-linkedin"></i></a></li>
						<li className="sc-link"><a href={"https://www.youtube.com/channel/UCll_R-7Xs6zxbvKjqD8fXpA"}><i className="fa fa-youtube"></i></a></li>
					</ul> */}
					<Link to={"#"} className="">Contáctanos</Link>
				</div>
				<div className="contact-sidebar content-scroll">
					<div className="inner">
						<div className="logo mb-4 text-center">
							<img src={Logo} alt=""/>
						</div>
						<div className="row text-center m-2 ">
							<div className='col-4 md-12'>
							<h3><a className="textcolor-black" href={"https://twitter.com/ScienceVico"}><i className="fa fa-twitter" style={{fontSize: "25px"}}></i></a></h3>
							</div>
							<div className='col-4 md-12'>
							<h3><a className="textcolor-black" href={"https://www.linkedin.com/company/vico-science/"}><i className="fa fa-linkedin" style={{fontSize: "25px"}}></i></a></h3>
							</div>
							<div className='col-4 md-12'>
							<h3><a className="textcolor-black" href={"https://www.youtube.com/channel/UCll_R-7Xs6zxbvKjqD8fXpA"}><i className="fa fa-youtube" style={{fontSize: "25px"}}></i></a></h3>
							</div>
						</div>
						<h4 className="title">Contáctate con nosotros</h4>
						<ul className="contact-infolist">
							<li>
								<img src={IconImg3} alt=""/>
								<h6 className="contact-title">Oficina</h6>
								<p>General del Canto 50 oficina 301 <br/>Providencia, Santiago, Chile</p>
							</li>
						</ul>
						<h4 className="title">Hablemos</h4>
						{!this.state.formSubmitted && (
							<form className="contact-bx ajax-form" onSubmit={this.handleSubmit}>
								<input type="hidden" name="_subject" value="Contacto vicobusiness.com (sidebar)"/>	
								<input type="hidden" name="_next" value="http://vicobusiness.com/gracias"/>
								<input type="hidden" name="_cc" value="yasnapardo@vicoscience.com,cristianaguirre@vicoscience.com"/>
								<input type="hidden" name="_template" value="table"/>
								<div className="ajax-message"></div>
								<div className="row placeani">
									<div className="col-lg-12">
										<div className="form-group">
											<div className="input-group">
												<label>Nombre</label>
												<input name="name" type="text" required className="form-control"/>
											</div>
										</div>
									</div>
									<div className="col-lg-12">
										<div className="form-group">
											<div className="input-group"> 
												<label>Correo</label>
												<input name="email" type="email" className="form-control" required />
											</div>
										</div>
									</div>
									<div className="col-lg-12">
										<div className="form-group">
											<div className="input-group">
												<label>Mensaje</label>
												<textarea name="message" rows="4" className="form-control" required ></textarea>
											</div>
										</div>
									</div>
									<div className="col-lg-12">
										<button name="submit" type="submit" valueDefault="Submit" className="btn button-md"> Enviar</button>
									</div>
								</div>
							</form>
						)}
						{this.state.formSubmitted && (
							<div className="thank-you-message">
								<h5>¡Muchas gracias por tu mensaje!</h5>
								<p>Pronto te contactaremos.</p>
							</div>
						)}
					</div>
				</div>
				<div className="contact-sidebtn close" id="clase-btn">
					<i className="ti-close"></i>
				</div>
			</>
		);
	}
}

export default ContactFooter;