import React, { Component } from 'react';
import {Link } from 'react-router-dom';
import {Link as ScrollTo} from 'react-scroll'
import ModalVideo from 'react-modal-video'

// Layout
import Header from '../../layout/header/header';
import Footer from '../../layout/footer/footer1';

// Elements
import ClientLogo from "../../elements/client-logo/logo-carousel";

// Images
import pattern1 from '../../../images/pattern/pt1.png';
import aboutPic2 from '../../../images/about/pic2.jpg';
import bannerPic1 from '../../../images/background/bg1.jpg';
import servicesPic2 from '../../../images/video/logo_intro_video.png';
import servicesPic3 from '../../../images/our-services/pic3.jpg';
import servicesPic4 from '../../../images/our-services/pic4.jpg';
import ContactButton from '../../elements/contact-button';



class Services3 extends Component {
	
	constructor () {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal () {
		this.setState({isOpen: true})
	}

	render(){
		return (
			<>
				<Header />
				
				<div className="page-content bg-white">
					<div className="page-banner ovpr-dark overlay-dotted ovdt2 parallax" style={{backgroundImage: "url("+bannerPic1+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">VICO Business</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i>Inicio</Link></li>
										<li>Ley I+D</li>
									</ul>
								</div>
							 </div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
					</div>
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp3" >
							<div className="container">
							<div className="heading-bx text-center">
									<h2 className="title-head">Financiamiento Ley I+D</h2>
									<p>Te asesoramos y acompañamos en el proceso de obtención de financiamiento a través de la Ley I+D.</p>
								</div>
								<div>
									<div className="service-img-bx" >
										<div className="service-media">
											<div className="video-media-bx">
												<img src={servicesPic2} alt=""/>
												<Link to={"#"} onClick={this.openModal} className="popup-youtube"><i className="fa fa-play"></i></Link>
											</div>
										</div>
										<div className="service-info">
											<div className="title">
												<h3>Financiamiento Ley I+D</h3>
												<p>Te asesoramos y acompañamos en el proceso de obtención de financiamiento a través de la Ley I+D.</p>
											</div>	
											<ul className="list-check right">
												<li>Beneficio tributario directo de hasta un 35% y de manera indirecta hasta un 65% de los costos totales de tu proyecto de I+D. </li>
												<li>Gastos de RRHH, contratación de terceros, operaciones, entre otros.  </li>
												<li><a href="https://www.corfo.cl/sites/cpp/incentivo_tributario" _blank>Conoce más acerca de la Ley I+D</a></li>
											</ul>
											<ContactButton/>
											{/* <Link to={"/contact"} className="btn radius-xl">Hablemos</Link> */}
										</div>
									</div>
									
								</div>
							</div>
						</div>
						
					</div>
					
				</div>
				
				<Footer />
				
				<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='mGgd40-9gYo' onClose={() => this.setState({isOpen: false})} />
				
			</>
		);
	}
}

export default Services3;