import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import logo from '../../../images/logo-white.png';
import ContactNavbar from '../../elements/contact-navbar';
import ContactButton from '../../elements/contact-button';
import ContactFooter from '../../elements/contact-footer';

class Footer1 extends Component{

	// componentDidMount() {

	// 				// Contact Sidebar
	// 				var asideBtn = document.querySelector(".slideinfonav")
	// 				var contactSidebar = document.querySelector(".contact-sidebar")
	// 				//var closeBtn = document.getElementById("clase-btn")
	
	// 				asideBtn.addEventListener('click',function(){
	// 						contactSidebar.classList.add("open")
	// 				})
	
	// 				// closeBtn.addEventListener('click',function(){
	// 				// 		contactSidebar.classList.remove("open")
	// 				// }) 
		
  //   }

	render(){
		return(
			<>
				<footer>
					<div className="footer-top">
						<div className="container">
							<div className="row">
								<div className="col-12 col-lg-8 col-md-12 col-sm-12 order-2 order-lg-1">
									<div className="row">
										<div className="col-12 col-lg-6 col-md-6 col-sm-6">
											<div className="widget list-2">
												<h5 className="footer-title">VICO Business</h5>
												<ul>
													<li><Link to={"/"}>INICIO</Link></li>
													<li><Link to={"quienes-somos"}>VICO Biz</Link></li>
													<li><Link to={"preguntas-frecuentes"}>PREGUNTAS FRECUENTES</Link></li>
													<li><Link to={"equipo-vicobiz"}>EQUIPO</Link></li>
													<li><Link to={"vr-ar-xr"}>VR/AR/XR</Link></li>
													<li><Link to={"consultoria-ley-i-d"}>LEY I+D</Link></li>
													<li><Link to={"visualizacion"}>VISUALIZACIÓN</Link></li>
												</ul>
											</div>
										</div>
										<div className="col-12 col-lg-6 col-md-6 col-sm-6">
											<div className="widget footer_talkus">
												<h5 className="footer-title">Contáctanos</h5>

												<ContactFooter />


												{/* <p><Link to={"Contact1"}>Formulario de Contacto</Link></p> */}

											
												{/* <li className="contact-list slideinfonav">
													<p><Link to={"#"}>Formulario de Contacto</Link></p>
												</li> */}
												
									
												<p className="mt-2">General del Canto 50 oficina 301 <br/>Providencia, Santiago</p>
												{/* <a href={"https://vicoscience.com/"} ></a> */}


												<ul className="footer-social">
													{/* <li>Redes sociales</li> */}
													<li className="sc-link"><a href={"https://twitter.com/ScienceVico"}><i className="fa fa-twitter"></i></a></li>
													<li className="sc-link"><a href={"https://www.linkedin.com/company/vico-science/"}><i className="fa fa-linkedin"></i></a></li>
													<li className="sc-link"><a href={"https://www.youtube.com/channel/UCll_R-7Xs6zxbvKjqD8fXpA"}><i className="fa fa-youtube"></i></a></li>
												</ul>

												

											

						



					
											</div>
										</div>
										<div className="col-12 col-lg-12 col-md-12 col-sm-12">
											<div className="footer-innner-btm">
												<div className="footer-logo">
													<Link to={"index"}><img src={logo} alt=""/></Link>
												</div>
												<div className="copyright-bx">
													<p>© {(new Date().getFullYear())} VICO Business. Marca registrada.</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
				</footer>
				
			</>
		);
	}
}

export default Footer1;
