import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll'
import ModalVideo from 'react-modal-video'

// Layout
import Header from '../../layout/header/header';
import Footer from '../../layout/footer/footer1';

// Elements
import ClientLogo from "../../elements/client-logo/logo-carousel";

// Images
import pattern1 from '../../../images/pattern/pt1.png';
import bannerPic1 from '../../../images/background/bg4.jpg';
import servicesPic2 from '../../../images/video/logo_intro_video.png';
import servicesPic3 from '../../../images/our-services/pic3.jpg';
import visualizacionDataCompleja0 from '../../../images/our-services/visualizacion_data_compleja0.jpg';
import visualizacionDataCompleja1 from '../../../images/our-services/visualizacion_data_compleja1.jpg';
import visualizacionDataCompleja2 from '../../../images/our-services/visualizacion_data_compleja2.jpg';
import visualizacionDataCompleja3 from '../../../images/our-services/visualizacion_data_compleja3.jpg';
import ContactButton from '../../elements/contact-button';



class Services3 extends Component {

	

	constructor() {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal() {
		this.setState({ isOpen: true })
	}

	render() {
		return (
			<>
				<Header />

				<div className="page-content bg-white">
					<div className="page-banner ovpr-dark overlay-dotted ovdt2 parallax" style={{ backgroundImage: "url(" + bannerPic1 + ")" }}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">Guías interactivas para procesos y políticas corporativas</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i>Inicio</Link></li>
										<li>Visualización</li>
									</ul>
								</div>
							</div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
					</div>
					<div className="content-block" id="content-area">

						<div className="section-area section-sp3" >
							<div className="container">
								<div className="heading-bx text-center">
									<h3 className="title-head">¿Quieres pasar del Excel a una <br/>estrategia eficiente de comunicación de data?</h3>
									<p>Desarrollo de nuevas técnicas de visualización para transformar los datos desde fuentes como excel de manera dinámica y atractiva que permite relevar información clave de una manera efectiva.</p>
								</div>
								<div>
								<div className="service-img-bx">
										<div className="service-media">
											<div className="video-media-bx">
												<img src={visualizacionDataCompleja0} alt="" />
											</div>
										</div>
										<div className="service-info">
											<div className="title">
												<h3>Desarrollos</h3>
												<p>Analizamos tu data y los objetivos de comunicación, para generar una propuesta <i>Data Viz</i> coherente con tu marca y eficiente a nivel de comunicación.</p>
											</div>
											<ul className="list-check">
												<li>Analizar</li>
												<li>Diseñar</li>
												<li>Testear</li>
											</ul>
											{/*<Link to={"/contact-1"} className="btn radius-xl">Caso</Link>*/}
											<a target="_blank" href="https://www.ccs.cl/html/guia/">Ver ejemplo de implementación del servicio</a>
										</div>
									</div>
									<div className="service-img-bx">
										<div className="service-media">
											<div className="video-media-bx">
												<img src={visualizacionDataCompleja1} alt="" />
											</div>
										</div>
										<div className="service-info">
											<div className="title">
												<h3>Metodología</h3>
												<p>Utilizamos nuestra propia metodología para potenciar una adecuada experiencia de usuario y adopción de la información.</p>
												<p>Integramos enfoques metodológicos de la Ingeniería y el Diseño, para lograr una aproximación eficiente a cada proyecto.</p>
												<p>Nuestros pilares fundamentales son:</p>
											</div>
											<ul className="list-check right">
												<li>Design Thinking</li>
												<li>Filosofía Agile</li>
											</ul>
										</div>
									</div>
									<div className="service-img-bx" >
										<div className="service-media">
											<div className="video-media-bx">
												<img src={visualizacionDataCompleja2} alt="" />
											</div>
										</div>
										<div className="service-info">
											<div className="title">
												<h3>Design Thinking</h3>
												<p>Enfoque de innovación centrado en el humano, basado en las herramientas de diseño para integrar las necesidades de las personas, las posibilidades tecnológicas y los requerimientos para el éxito empresarial.</p>
											</div>
											<ul className="list-check left">
												<li>Trabajo colaborativo con el equipo del cliente</li>
												<li>Diseño de experiencia de usuario (UX)</li>
												<li>Enfoque en la adopción tecnológica</li>
											</ul>
										</div>
									</div>
									<div className="service-img-bx" >
										<div className="service-media">
											<div className="video-media-bx">
												<img src={visualizacionDataCompleja3} alt="" />
											</div>
										</div>
										<div className="service-info">
											<div className="title">
												<h3>Agile</h3>
												<p>Scrum es un esquema de trabajo para el desarrollo de software ágil, basado la colaboración en equipo e iteraciones cortas.</p>
											</div>
											<ul className="list-check right">
												<li>Iteraciones cortas</li>
												<li>Visibilidad de los resultados</li>
											</ul>
											<ContactButton/>
											
											{/* <Link to={"/contacto"} className="btn radius-xl">Hablemos</Link> */}
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>

				</div>

				<Footer />

				<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='mGgd40-9gYo' onClose={() => this.setState({ isOpen: false })} />

			</>
		);
	}
}

export default Services3;