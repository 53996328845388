import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';

// Images
import logo from '../../../images/logo.png';
import logoWhite from '../../../images/logo-white.png';
import adv from '../../../images/adv/adv.jpg';

class Header extends Component{
	
	componentDidMount() {
		
		// Search Form Popup
		 /*var searchBtn = document.getElementById("quik-search-btn")
        var searchForm = document.querySelector(".nav-search-bar")
        var closeBtn = document.getElementById("search-remove")
		
        searchBtn.addEventListener('click',function(){
            searchForm.classList.add("show")
        })

        closeBtn.addEventListener('click',function(){
            searchForm.classList.remove("show")
        })*/

        // Mobile Menu sidebar function
       var btn = document.querySelector('.menuicon');
        var nav = document.querySelector('.menu-links');
       
        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);
		

        // Mobile Submenu open close function
        var navMenu = [].slice.call(document.querySelectorAll('.menu-links > ul > li'));
        for (var y = 0; y < navMenu.length; y++) {
            navMenu[y].addEventListener('click', function () { menuClick(this) });
        }

        function menuClick(current) {
            const active = current.classList.contains("open")
            navMenu.forEach(el => el.classList.remove('open'));
            
            if(active){
                current.classList.remove('open') 
                console.log("active")
            } else{
                current.classList.add('open');
                console.log("close")
            }
        }
		
    }
	
	render(){
		return(
			<>
				{/* <!-- Header Top ==== --> */}
				<header className="header rs-nav header-transparent">
					<Sticky enabled={true} className="sticky-header navbar-expand-lg">
						<div className="menu-bar clearfix">
							<div className="container-fluid clearfix">
								{/* <!-- Header Logo ==== --> */}
								<div className="menu-logo">
									<Link to={"/"}><img src={logoWhite} alt=""/></Link>
								</div>
								{/* <!-- Mobile Nav Button ==== --> */}
								<button className="navbar-toggler collapsed menuicon justify-content-end" type="button" data-toggle="collapse" data-target="#menuDropdown" aria-controls="menuDropdown" aria-expanded="false" aria-label="Toggle navigation">
									<span></span>
									<span></span>
									<span></span>
								</button>
								{/* <!-- Author Nav ==== --> 
								<div className="secondary-menu">
									<div className="secondary-inner">
										<ul>
											<li className="search-btn"><button id="quik-search-btn" type="button" className="btn-link"><i className="fa fa-search"></i></button></li>
										</ul>
									</div>
								</div>*/}
								{/* <!-- Navigation Menu ==== --> */}
								<div className="menu-links navbar-collapse collapse justify-content-end" id="menuDropdown">
									<div className="menu-logo">
										<Link to={"/"}><img src={logo} alt=""/></Link>
									</div>
									<ul className="nav navbar-nav">	
										<li className=""><Link to={"#"}>Nosotros<i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu">
												<li><Link to={"about-1"}><span>Somos</span> </Link></li>
												<li><Link to={"team"}><span>Equipo</span></Link></li>
												<li><Link to={"faq-1"}><span>FAQ's</span></Link></li>
											</ul>
										</li>
										<li><Link to={"services-3"}><span>Servicios</span></Link></li>
										<li><a href={"/#exp"}><span>Experiencia</span></a></li>
										<li><a href={"/#casos"}><span>Casos</span></a></li>
									</ul>
									<div className="nav-social-link">
										<a href={"https://twitter.com/ScienceVico"}><i className="fa fa-twitter"></i></a>
										<a href={"https://cl.linkedin.com/company/vico-science"}><i className="fa fa-linkedin"></i></a>
										<a href={"https://www.youtube.com/channel/UCll_R-7Xs6zxbvKjqD8fXpA"}><i className="fa fa-youtube"></i></a>
									</div>
								</div>
								{/* <!-- Navigation Menu END ==== --> */}
							</div>
						</div>
					</Sticky>
					{/* <!-- Search Box ==== --> 
					<div className="nav-search-bar">
						<form action="#">
							<input name="search" defaultValue="" type="text" className="form-control" placeholder="Type to search"/>
							<span><i className="ti-search"></i></span>
						</form>
						<span id="search-remove"><i className="ti-close"></i></span>
					</div>
					*/}
				</header>
				{/* <!-- Header Top END ==== --> */}
			</>
		);
	}
}

export default Header;
