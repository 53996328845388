import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Link as ScrollTo} from 'react-scroll'
import {Accordion, Card} from 'react-bootstrap';

// Layout
import Header1 from "../../layout/header/header";
import Footer from "../../layout/footer/footer1";

// Elements
import Testimonial from "../../elements/testimonial/testimonial2";

// Images
import bannerPic1 from '../../../images/banner/banner1.jpg';
import pattern1 from '../../../images/pattern/pt1.png';
import bgPic1 from '../../../images/background/bg1.jpg';
import ContactButton from '../../elements/contact-button';

class Faq extends Component {
	
	render(){
		return (
			<>
				<Header1 />
				
				<div className="page-content bg-white">
					<div className="page-banner ovbl-dark page-banner-lg ovdt1 overlay-dotted no-pt parallax" style={{backgroundImage: "url("+bannerPic1+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<span className="banner-sm-title">Preguntas Frecuentes</span>
								<h1 className="text-white">Estamos atentos para <br/> satisfacer tus requerimientos.</h1>
							 </div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
					</div>
					<div className="content-block" id="content-area">
						<div className="section-area section-sp2 team-page bg-gray" style={{backgroundImage: "url("+pattern1+")"}}>
							<div className="container">
								
								<div className="row">
									<div className="col-lg-6 col-md-6 col-sm-12">
										<Accordion className="ttr-accordion faq-bx" defaultActiveKey="0">
											<Card>
												<Accordion.Toggle as={Card.Header} eventKey="0">
													<Link to="#">¿Quienes pueden optar al beneficio de la Ley de I+D?</Link>
												</Accordion.Toggle>
												<Accordion.Collapse eventKey="0">
													<Card.Body>
													Pueden acceder personas naturales o jurídicas afectadas al impuesto de Primera Categoría de la Ley sobre Impuesto a la Renta, que declaren su renta efectiva según contabilidad completa, siempre que dispongan de un contrato o proyecto debidamente certificado por Corfo, conforme a los requisitos que la misma Ley establece.
													</Card.Body>
												</Accordion.Collapse>
											</Card>
											<Card>
												<Accordion.Toggle as={Card.Header} eventKey="1">
													<Link to="#">¿Cuánto es el ahorro para una empresa?</Link>
												</Accordion.Toggle>
												<Accordion.Collapse eventKey="1">
													<Card.Body>
													Un crédito tributario contra el impuesto de primera categoría, equivalente a un 35% de los desembolsos efectuados por la empresa, conforme a la realización de actividades de I+D en el contexto de contratos o proyectos que han sido certificados por Corfo. El 65% restante, los contribuyentes podrán rebajar, como gasto necesario para producir la renta.
													</Card.Body>
												</Accordion.Collapse>
											</Card>
											
										</Accordion>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-12">
										<Accordion className="ttr-accordion faq-bx" defaultActiveKey="0">
											<Card>
												<Accordion.Toggle as={Card.Header} eventKey="2">
													<Link to="#">¿Qué es la tecnología XR y cómo se puede aplicar a empresas?</Link>
												</Accordion.Toggle>
												<Accordion.Collapse eventKey="2">
													<Card.Body>
													La Realidad Extendida (XR), a menudo denominada "XR", es el término general utilizado para la Realidad Virtual (VR), la Realidad Aumentada (AR) y la Realidad Mixta (MR), así como las realidades futuras que las tecnologías inmersivas podrían crear.
Después de años de investigación y desarrollo, la tecnología XR ha alcanzado un punto de inflexión en el que se vuelve accesible para las pequeñas empresas y los consumidores, lo que garantiza su introducción en el mercado. Con más de $ 24 mil millones en inversiones en todo el mundo hasta la fecha, la industria está preparada para un futuro brillante en campos como educación, entrenamiento, diseño, salud, tratamientos e investigación de mercado.
													</Card.Body>
												</Accordion.Collapse>
											</Card>
											<Card>
												<Accordion.Toggle as={Card.Header} eventKey="3">
													<Link to="#">¿Qué actividades se consideran de I+D?</Link>
												</Accordion.Toggle>
												<Accordion.Collapse eventKey="3">
													<Card.Body>
													Proyectos de investigación básica e investigación aplicada que son trabajos originales realizados para adquirir nuevos conocimientos; dirigida fundamentalmente hacia un objetivo práctico específico. Por otra parte, desarrollo que son trabajos sistemáticos que aprovechan los conocimientos existentes obtenidos de la investigación y/o la experiencia, y está dirigido a la producción de nuevos materiales, productos o dispositivos; a la puesta en marcha de nuevos procesos, sistemas y servicios, o a la mejora sustancial de los ya existentes. Comprende el desarrollo de programas informáticos. 
													</Card.Body>
												</Accordion.Collapse>
											</Card>
											<Card>
												<Accordion.Toggle as={Card.Header} eventKey="3">
													<Link to="#">¿Qué es Data Viz?</Link>
												</Accordion.Toggle>
												<Accordion.Collapse eventKey="3">
													<Card.Body>
													La visualización de datos y diseño de la información de manera gráfica que presenta datos complejos de forma clara a través de piezas atractivas que comuniquen información con facilidad. 
													</Card.Body>
												</Accordion.Collapse>
											</Card>
											
										</Accordion>
									</div>
								</div>
							</div>
						</div>
						<div className="text-center section-sp4">
									<h3 className="title-head m-b20">¿Trabajemos juntos?</h3>
									<ContactButton/>
									{/* <Link to={"contact"} className="btn button-md radius-xl">Hablemos</Link> */}
								</div>
					</div>
				</div>
				
				<Footer/>
				
			</>
		);
	}
}

export default Faq;