import React, { Component } from 'react';
import Slider from "react-slick"; 

// Images
import TestiPic1 from "../../../images/testimonials/testimonial_andrea_lizana.png"
import TestiPic2 from "../../../images/testimonials/testimonial_francisco_meneses.png"
import TestiPic3 from "../../../images/testimonials/testimonial_christian_beyle.png"
import TestiPic4 from "../../../images/testimonials/testimonial_yasna_pardo.png"

const content = [
	/*{
		thumb: TestiPic2,
		name: "Francisco Meneses",
		work: "Senior Innovation Consultant",
		text: "Apoyo en la gestión de beneficio Ley I+D para proyecto de mejora genética de especies forestales.",
    },*/
	{
		thumb: TestiPic3,
		name: "Christian Beyle",
		work: "Science Director",
		text: "Proyectos de implementación tecnológica en diversas industrias; Asesoramiento metodológico para modelos de evaluación conductual y teórico.",
    },
	{
		thumb: TestiPic1,
		name: "Andrea Lizana",
		work: "UX Director",
		text: "Acompañamiento en el proceso de levantamiento de requerimientos e investigación del usuario, desarrollo de propuestas de diseño de información y experiencia de usuario junto al testeo de interfaces y el desarrollo de una solución a partir del diseño.",
    },
	/*{
		thumb: TestiPic4,
		name: "Yasna Pardo",
		work: "Senior Innovation Consultant",
		text: "Apoyo en la gestión de beneficio Ley I+D para proyecto de automatización de procesos en la industria gráfica.",
    },*/
]

class Testimonial2 extends Component{
	render(){
		
		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 2,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
		
		return(
			<>
				<Slider {...settings} className="testimonial-carousel owl-btn-center-lr owl-btn-1">
					{content.map((item, id) => (
						<div className="slider-item">
							<div className="testimonial-bx">
								<div className="testimonial-content">
									<p>{item.text}</p>
								</div>
								<div className="testimonial-info">
									<h5 className="name">{item.name}</h5>
									<p>{item.work}</p>
								</div>
								<div className="testimonial-pic">
									<img src={item.thumb} alt=""/>
								</div>
							</div>
						</div>
					))}
				</Slider>
			</>
		);
	}
}

export default Testimonial2;