import React, { Component } from 'react';
import {Link } from 'react-router-dom';
import {Link as ScrollTo} from 'react-scroll'
import ModalVideo from 'react-modal-video'

// Layout
import Header from '../../layout/header/header';
import Footer from '../../layout/footer/footer1';

// Elements
import ClientLogo from "../../elements/client-logo/logo-carousel";

// Images
import pattern1 from '../../../images/pattern/pt1.png';
import aboutPic2 from '../../../images/about/pic2.jpg';
import bannerPic1 from '../../../images/background/VRVico.png';
import servicesPic2 from '../../../images/video/logo_intro_video.png';
import servicesPic3 from '../../../images/our-services/pic3.jpg';
import servicesPic4 from '../../../images/our-services/pic4.jpg';
import ContactButton from '../../elements/contact-button';



class Services3_1 extends Component {
	
	constructor () {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal () {
		this.setState({isOpen: true})
	}

	render(){
		return (
			<>
				<Header />
				
				<div className="page-content bg-white">
					<div className="page-banner ovpr-dark overlay-dotted ovdt2 parallax" style={{backgroundImage: "url("+bannerPic1+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">VICO Business</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i>Inicio</Link></li>
										<li>Somos</li>
									</ul>
								</div>
							 </div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
					</div>
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp3" >
							<div className="container">
								<div className="heading-bx text-center">
									<h2 className="title-head">Consultoría y desarrollo <br/>de proyectos de XR</h2>
									<p>A través del área de servicios para las industrias (VICO Business) desarrollamos proyectos de innovación para la industria 4.0. Integramos diversas tecnologías junto a procesos de diseño ágil y basado en evidencia, para generar soluciones.</p>
								</div>
								<div>
									<div className="service-img-bx">
										<div className="service-media">
											<div className="video-media-bx">
												<img src={servicesPic3} alt=""/>
											</div>
										</div>
										<div className="service-info">
											<div className="title">
												<h3>Proyectos de Innovación</h3>
												<p>Te ayudamos a concretar/desarrollar/llevar adelante tus iniciativas con servicios a medida o pre-empaquetados en ámbitos como captura y procesamiento de datos, evaluaciones conductuales o neuromarketing, desde el diseño a su implementación, considerando componentes de investigación y desarrollo necesarios para optar a los beneficios de la Ley I+D.</p>
											</div>	
											<ul className="list-check">
												<li>Captura, gestión y análisis de datos</li>
												<li>Diseño y desarrollro de proyectos XR (Realidad eXtendida)</li>
												<li>Integración de tecnologías y hardware</li>
											</ul>
											{/* <Link to={"/contact"} className="btn radius-xl">Hablemos</Link> */}
											<ContactButton/>
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
					
				</div>
				
				<Footer />
				
				<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='mGgd40-9gYo' onClose={() => this.setState({isOpen: false})} />
				
			</>
		);
	}
}

export default Services3_1;