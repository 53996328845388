import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Sticky from 'react-stickynode';

// Images
import logo from '../../../images/logo.png';
import logoWhite from '../../../images/logo-white.png';
import adv from '../../../images/adv/adv.jpg';
import Index from '../../pages/home';

//import IconImg3 from "../../images/icon/contact/icon3.png"
import IconImg3 from "../../../images/icon/contact/icon3.png"
import Logo from "../../../images/logo.png"
//import Logow from "../../images/logo.png"

import ContactSidebar from '../../elements/contact-sidebar';
import ContactButton from '../../elements/contact-button';
import ContactNavbar from '../../elements/contact-navbar';

class Header extends Component{
	
	componentDidMount() {
		
		// Search Form Popup
		/*var searchBtn = document.getElementById("quik-search-btn")
        var searchForm = document.querySelector(".nav-search-bar")
        var closeBtn = document.getElementById("search-remove")
		
        searchBtn.addEventListener('click',function(){
            searchForm.classList.add("show")
        })

        closeBtn.addEventListener('click',function(){
            searchForm.classList.remove("show")
        })*/

        // Mobile Menu sidebar function
        var btn = document.querySelector('.menuicon');
        var nav = document.querySelector('.menu-links');
       
        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);

        // Mobile Submenu open close function
        var navMenu = [].slice.call(document.querySelectorAll('.menu-links > ul > li'));
        for (var y = 0; y < navMenu.length; y++) {
            navMenu[y].addEventListener('click', function () { menuClick(this) });
        }

        function menuClick(current) {
            const active = current.classList.contains("open")
            navMenu.forEach(el => el.classList.remove('open'));
            
            if(active){
                current.classList.remove('open') 
                console.log("active")
            } else{
                current.classList.add('open');
                console.log("close")
            }
        }


					// Contact Sidebar
					// var asideBtn = document.querySelector(".slideinfonav")
					// var contactSidebar = document.querySelector(".contact-sidebar")
					// var closeBtn = document.getElementById("clase-btn")
	
					// asideBtn.addEventListener('click',function(){
					// 		contactSidebar.classList.add("open")
					// })
	
					// closeBtn.addEventListener('click',function(){
					// 		contactSidebar.classList.remove("open")
					// }) 



		
    }
	
	render(){
		return(
			<>
				{/* <!-- Header Top ==== --> */}
				<header className="header rs-nav header-transparent">
					<Sticky enabled={true} className="sticky-header navbar-expand-lg">
						<div className="menu-bar clearfix">
							<div className="container-fluid clearfix">
								{/* <!-- Header Logo ==== --> */}
								<div className="menu-logo">
									<NavLink exact activeClassName="menuSelected" to={"/"}><img src={logoWhite} alt=""/></NavLink>
								</div>
								{/* <!-- Mobile Nav Button ==== --> */}
								<button className="navbar-toggler collapsed menuicon justify-content-end" type="button" data-toggle="collapse" data-target="#menuDropdown" aria-controls="menuDropdown" aria-expanded="false" aria-label="Toggle navigation">
									<span></span>
									<span></span>
									<span></span>
								</button>
								{/* <!-- Author Nav ==== --> 
								<div className="secondary-menu">
									<div className="secondary-inner">
										<ul>
											<li className="search-btn"><button id="quik-search-btn" type="button" className="btn-link"><i className="fa fa-search"></i></button></li>
										</ul>
									</div>
								</div>*/}
								{/* <!-- Navigation Menu ==== --> */}
								<div className="menu-links navbar-collapse collapse justify-content-end" id="menuDropdown">
									<div className="menu-logo">
										<NavLink exact activeClassName="menuSelected" to={"/"}><img src={logo} alt=""/></NavLink>
									</div>
									<ul className="nav navbar-nav">	
										<li className="no-drop"><NavLink exact activeClassName="menuSelected" to={"/"}><span>Inicio</span></NavLink></li>
										<li className="prueba"><NavLink exact activeClassName="menuSelected" to={"/quienes-somos"}>Nosotros<i className="fa fa-chevron-down"></i></NavLink>
											<ul className="sub-menu">
												<li><NavLink exact activeClassName="menuSelected" to={"/quienes-somos"}><span>VICO Biz</span> </NavLink></li>
												<li><NavLink exact activeClassName="menuSelected" to={"/equipo-vicobiz"}><span>Equipo</span>	</NavLink></li>
												<li><NavLink exact activeClassName="menuSelected" to={"/preguntas-frecuentes"}><span>FAQ's</span>	</NavLink></li>
											</ul>
										</li>
										<li className="no-drop">
											<NavLink exact activeClassName="menuSelected" to={"/vr-ar-xr"}>
												<span>VR/AR/XR</span>
											</NavLink>
										</li>
										<li className="no-drop">
											<NavLink exact activeClassName="menuSelected" to={"/consultoria-ley-i-d"} >
												<span>LEY I+D</span>	
											</NavLink>
										</li>
										<li className="no-drop">
											<NavLink exact activeClassName="menuSelected" to={"/visualizacion"} >
												<span>Visualización</span>	
											</NavLink>
										</li>
									

										<li className="no-drop">

											{/* <Link to={"#"}>Contacto</Link> */}
											{/* <Link exact activeClassName="menuSelected" to={"<ContactNavbar/>"} >
lola
											</Link> */}

											<ContactNavbar/>
											
											
									
										</li>
										
									</ul>
									<div className="nav-social-link">
										<a href={"https://twitter.com/ScienceVico"}><i className="fa fa-twitter"></i></a>
										<a href={"https://cl.linkedin.com/company/vico-science"}><i className="fa fa-linkedin"></i></a>
										<a href={"https://www.youtube.com/channel/UCll_R-7Xs6zxbvKjqD8fXpA"}><i className="fa fa-youtube"></i></a>
									</div>
								</div>
								{/* <!-- Navigation Menu END ==== --> */}
							</div>
												{/* <div className="contact-sidebar content-scroll">
										<div className="inner">
											<div className="logo mb-4 text-center">
												<img src={Logo} alt=""/>
											</div>
											<div className="row text-center m-2 ">
												<div className='col-4 md-12'>
												<h3><a className="textcolor-black" href={"https://twitter.com/ScienceVico"}><i className="fa fa-twitter"></i></a></h3>
												</div>
												<div className='col-4 md-12'>
												<h3><a className="textcolor-black" href={"https://www.linkedin.com/company/vico-science/"}><i className="fa fa-linkedin"></i></a></h3>
												</div>
												<div className='col-4 md-12'>
												<h3><a className="textcolor-black" href={"https://www.youtube.com/channel/UCll_R-7Xs6zxbvKjqD8fXpA"}><i className="fa fa-youtube"></i></a></h3>
												</div>
											</div>
											<h4 className="title">Contáctate con nosotros</h4>
											<ul className="contact-infolist">
												<li>
													<img src={IconImg3} alt=""/>
													<h6 className="contact-title">Oficina</h6>
													<p>General del Canto 50 oficina 301 <br/>Providencia, Santiago, Chile</p>
												</li>
											</ul>
											<h4 className="title">Hablemos</h4>
											<form className="contact-bx ajax-form" action="https://formsubmit.co/7b86bd032be58a42c3b256f6d1963cef" method="POST">
												<input type="hidden" name="_subject" value="Contacto vicobusiness.com (sidebar)"/>	
												<input type="hidden" name="_next" value="http://vicobusiness.com/gracias"/>
												<input type="hidden" name="_cc" value="yasnapardo@vicoscience.com,cristianaguirre@vicoscience.com"/>
												<input type="hidden" name="_template" value="table"/>
												<div className="ajax-message"></div>
												<div className="row placeani">
													<div className="col-lg-12">
														<div className="form-group">
															<div className="input-group">
																<label>Nombre</label>
																<input name="name" type="text" required className="form-control"/>
															</div>
														</div>
													</div>
													<div className="col-lg-12">
														<div className="form-group">
															<div className="input-group"> 
																<label>Correo</label>
																<input name="email" type="email" className="form-control" required />
															</div>
														</div>
													</div>
													<div className="col-lg-12">
														<div className="form-group">
															<div className="input-group">
																<label>Mensaje</label>
																<textarea name="message" rows="4" className="form-control" required ></textarea>
															</div>
														</div>
													</div>
													<div className="col-lg-12">
														<button name="submit" type="submit" valueDefault="Submit" className="btn button-md"> Enviar</button>
													</div>
												</div>
											</form>
										</div>
									</div>
									<div className="contact-sidebtn close" id="clase-btn">
								<i className="ti-close"></i>
							</div> */}
						</div>
					</Sticky>
					{/* <!-- Search Box ==== --> 
					<div className="nav-search-bar">
						<form action="#">
							<input name="search" defaultValue="" type="text" className="form-control" placeholder="Type to search"/>
							<span><i className="ti-search"></i></span>
						</form>
						<span id="search-remove"><i className="ti-close"></i></span>
					</div>*/}
	
				</header>
				{/* <!-- Header Top END ==== --> */}
			</>
		);
	}
}

export default Header;
