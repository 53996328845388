import React, { Component } from 'react';
import {Link } from 'react-router-dom';
import {Link as ScrollTo} from 'react-scroll';

// Layout
import Header from '../../layout/header/header';
import Footer from '../../layout/footer/footer1';

// Images
import bannerPic1 from '../../../images/banner/banner1.jpg';
import pattern1 from '../../../images/pattern/pt1.png';
import ChristianBlanco from "../../../images/team/profile_christian_blanco.png"
import Team2 from "../../../images/team/profile_andrea_lizana.png"
import Team3 from "../../../images/team/profile_francisco_meneses.png"
import Team4 from "../../../images/team/profile_yasna_pardo.png"
import ChristianBeyle from "../../../images/team/profile_christian_beyle.png"
import CristianAguirre from "../../../images/team/profile_cristian_aguirre.png"
import YasnaPardo from "../../../images/team/profile_yasna_pardo.png"
import FelipeNunez from "../../../images/team/profile_felipe_nunez.png"
import MagdalenaMunos from "../../../images/team/profile_magdalena_munoz.png"
import ContactButton from '../../elements/contact-button';

const content = [
	/*{
		thumb: Team4,
		Name: "Yasna Pardo",
		Work: "Senior Innovation Consultant",
		Hono: "Magister Gestión de Políticas Públicas",
		Linkedin:"https://www.linkedin.com/in/yasnapardocarabante/",
    },*/
	{
		thumb: ChristianBlanco,
		Name: "Christian Blanco",
		Work: "Senior Innovation Consultant",
		Hono: "Magister en Sociología",
		Linkedin:"https://www.linkedin.com/in/christian-blanco-538152a/",
    },
		{
			thumb: YasnaPardo,
			Name: "Yasna Pardo",
			Work: "Magister en Gestión y Políticas Públicas",
			Hono: "Senior Innovation Consultant",
			Linkedin:"https://www.linkedin.com/in/yasnapardocaravante/",
		},
	// {
	// 	thumb: Team2,
	// 	Name: "Andrea Lizana",
	// 	Work: "Senior Innovation Consultant",
	// 	Hono: "Diseñadora Industrial",
	// 	Linkedin:"https://www.linkedin.com/in/andrea-soledad-lizana-solis-9b3b4222/",
  //   },
	/*{
		thumb: Team3,
		Name: "Francisco Meneses",
		Work: "Senior Innovation Consultant",
		Hono: "Doctor en Economía Política",
		Linkedin:"https://www.linkedin.com/in/francisco-juan-meneses-ponzini-7b06bb31/",
    },*/
	{
		thumb: ChristianBeyle,
		Name: "Christian Beyle",
		Work: "Director científico",
		Hono: "Doctor en Psicología",
		Linkedin:"https://www.linkedin.com/in/christian-beyle-b9052a23/",
    },
	{
		thumb: CristianAguirre,
		Name: "Cristian Aguirre",
		Work: "Senior Innovation Consultant",
		Hono: "Ingeniería informática",
		Linkedin:"https://www.linkedin.com/in/christian-beyle-b9052a23/",
    },

		{
			thumb: MagdalenaMunos,
			Name: "Magdalena Muñoz",
			Work: "Marketing 360",
			Hono: "Gerenta de Marketing",
			Linkedin:"https://www.linkedin.com/in/magdalenamunoz/",
		},
	{
		thumb: FelipeNunez,
		Name: "Felipe Nuñez",
		Work: "Comunicador Audiovisual y Especialista 3D",
		Hono: "Data Visualization",
		Linkedin:"https://www.linkedin.com/in/felipenunezde/",
	}


]

class Team extends Component {
	
	render(){
		return (
			<>
				<Header />
				
				<div className="page-content bg-white">
					<div className="page-banner ovbl-dark page-banner-lg ovdt1 overlay-dotted no-pt parallax" style={{backgroundImage: "url("+bannerPic1+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<span className="banner-sm-title">Conócenos</span>
								<h1 className="text-white">Ciencia aplicada a la industria</h1>
							 </div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
					</div>
					
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp1 team-page" style={{backgroundImage: "url("+pattern1+")"}}>
							<div className="container">
								<div className="heading-bx text-center">
								<h2 className="title-head m-b0">El equipo</h2>
									<p className="m-b0">Somos un equipo ágil y apasionado por la investigación, el desarrollo y la innovación; conformado por ingenieros, sociólogos, psicólogs, marketeros, diseñadores, programadores, desarrolladores y otros.</p>
								</div>
								<div className="row justify-content-center">
									{content.map((item, id) => (
										<div className="col-lg-4 col-md-6 col-sm-6">
											<div className="team-mamber m-b20">
												<div className="team-media">
													<img src={item.thumb} alt=""/>
													<ul className="team-social">
														<li><Link to={item.Linkedin}><i className="fa fa-linkedin"></i></Link></li>
													</ul>
												</div>
												<div className="team-info">
													<h4 className="title"><Link to={"#"}>{item.Name}</Link></h4>
													<span>{item.Hono}</span>
												<br/>
													<p className="title">{item.Work}</p>
												</div>
											</div>
										</div>
									))}
								</div>
								<div className="text-center section-sp4">
									<h3 className="title-head m-b20">¿Trabajemos juntos?</h3>
									<ContactButton/>
									{/* <Link to={"contact-1"} className="btn button-md radius-xl">Hablemos</Link> */}
								</div>
							</div>
						</div>					
						
					</div>
					
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default Team;