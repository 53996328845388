import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ModalVideo from 'react-modal-video'

// Layout
import Header from "../../layout/header/header";
import Footer from "../../layout/footer/footer1";

// Elements
import Count from '../../elements/counter/counter-sensor';
import MainSlider from '../../elements/main-slider/slider1';
import About from '../../elements/about/about1';
import Team from '../../elements/team/team2';
import Testimonial from '../../elements/testimonial/testimonial1';
import BlogSlider from '../../elements/blog/blog-slider';
import ContactSidebar from '../../elements/contact-sidebar';

// Images
import blogGridPic1 from '../../../images/blog/grid/pic1.jpg';
import aboutPic2 from '../../../images/video/logo_intro_video.png';
import bgPic2 from '../../../images/background/bg2.jpg';
import bgPic3 from '../../../images/background/bg3.jpg';
import partnerLogo1 from '../../../images/partner/logo1.png';
import partnerLogo2 from '../../../images/partner/logo2.png';
import partnerLogo3 from '../../../images/partner/logo3.png';
import partnerLogo4 from '../../../images/partner/logo4.png';
import partnerLogo5 from '../../../images/partner/logo5.png';
import partnerLogo6 from '../../../images/partner/logo6.png';
import partnerLogo7 from '../../../images/partner/logo7.png';
import partnerLogo8 from '../../../images/partner/logo8.png';
import partnerLogo9 from '../../../images/partner/logo9.png';

class Index extends Component{
	
	constructor () {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal () {
		this.setState({isOpen: true})
	}
	
	render(){
		return (
			<>
			
				
				<Header />
				
				<div className="page-content bg-white">
				
					<MainSlider />
					<ContactSidebar />
					
					<div className="content-block" id="content-area">
												
						<div name="exp" id="exp" className="section-area section-sp3 ovpr-dark bg-fix about-media-bx parallax" style={{backgroundImage: "url("+bgPic2+")"}}>
							<div className="container pb-4">
								<div className="row">
									<div className="col-lg-6 wow fadeInUp m-md-b30" data-wow-delay="0.4s">
										<div className="video-media-bx">
											<img src={aboutPic2} alt=""/>
											<Link to={"#"} onClick={this.openModal} className="popup-youtube"><i className="fa fa-play"></i></Link>
										</div>
									</div>
									<div className="col-lg-6 wow fadeIn backgroung-color-white pl-5 pr-5" data-wow-delay="0.8s" >
										<div className="heading-bx text-white m-t20">
											<br></br>	
											<h2 className="m-b10 textcolor-black">Recursos adjudicados</h2>
											<br></br>
											<h5 className="fw5 m-t15 textcolor-black">Hemos gestionado un número importante de proyectos para la industria en...</h5>
										</div>
										<br></br>
										<div className="row text-center">
											<div className="col-lg-4 col-md-4 col-sm-4 col-4">
												<div className="counter-style-1">
													<div className="text-secondry">
														<Count counter={6}/>													
														<span>+</span>
													</div>
													<span className="counter-text textcolor-black">Sectores</span>
												</div>
											</div>
											<div className="col-lg-4 col-md-4 col-sm-4 col-4">
												<div className="counter-style-1">
													<div className="text-secondry">
														<Count counter={70}/>
														<span>+</span>
													</div>
													<span className="counter-text textcolor-black">Millones USD</span>
												</div>
											</div>
											<div className="col-lg-4 col-md-4 col-sm-4 col-4">
												<div className="counter-style-1">
													<div className="text-secondry">
														<Count counter={10}/>
														<span>+</span>
													</div>
													<span className="counter-text textcolor-black">Años</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						{/* <div id="casos" className="section-area section-sp2 bg-fix ovprd-dark news-box parallax" style={{backgroundImage: "url("+bgPic3+")"}}>
							<div className="container posi-rl zi1">
								<div className="heading-bx text-white">
									<h2 className="m-b10">Estos son algunos ejemplos de las áreas <br/>en las que hemos trabajado</h2>
								</div>
								
								<Testimonial />
							
							</div>
						</div> */}
					</div>
				</div>

				<Footer/>
				
				<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='mGgd40-9gYo' onClose={() => this.setState({isOpen: false})} />
				
			</>
		);
	}
}

export default Index;