import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import logo from '../../../images/logo-white.png';

class Footer1 extends Component{
	render(){
		return(
			<>
				<footer>
					<div className="footer-top">
						<div className="container">
							<div className="row">
								<div className="col-12 col-lg-8 col-md-12 col-sm-12 order-2 order-lg-1">
									<div className="row">
										<div className="col-12 col-lg-6 col-md-6 col-sm-6">
											<div className="widget list-2">
												<h5 className="footer-title">VICO Business</h5>
												<ul>
													<li><Link to={"/"}>Inicio</Link></li>
													<li><Link to={"about-1"}>Somos</Link></li>
													<li><Link to={"faq-1"}>Preguntas frecuentes</Link></li>
													<li><Link to={"services-3"}>Servicios</Link></li>
													<li><Link to={"team"}>Equipo</Link></li>
												</ul>
											</div>
										</div>
										<div className="col-12 col-lg-6 col-md-6 col-sm-6">
											<div className="widget footer_talkus">
												<h5 className="footer-title">Contactanos</h5>
												<p>General del Canto 50 oficina 301 <br/>Providencia, Santiago</p>
												<a href={"https://vicoscience.com/"} ></a>
											</div>
										</div>
										<div className="col-12 col-lg-12 col-md-12 col-sm-12">
											<div className="footer-innner-btm">
												<div className="footer-logo">
													<Link to={"index"}><img src={logo} alt=""/></Link>
												</div>
												<div className="copyright-bx">
													<p>© 2022 VICO Business. Marca registrada.</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
				</footer>
				
			</>
		);
	}
}

export default Footer1;
