import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Link as ScrollTo} from 'react-scroll'
import ModalVideo from 'react-modal-video'

// Layout
import Header from "../../layout/header/header";
import Footer from "../../layout/footer/footer1";

// Elements
import LogoCarousel from "../../elements/client-logo/logo-carousel";
import Testimonial from "../../elements/testimonial/testimonial2";
import CaseStudies from "../../elements/case-studies/case-studies";
import Count from '../../elements/counter/counter-sensor';

// Images
import bannerPic1 from '../../../images/background/bg1.jpg';
import aboutPic7 from '../../../images/background/bg2.png';
import bgPic2 from '../../../images/background/bg2.jpg';
import aboutPic3 from '../../../images/video/logo_intro_video.png';
import aboutLogo from '../../../images/about/VBIZ2-12.png';
import ContactSidebar from '../../elements/contact-sidebar';
import ContactButton from '../../elements/contact-button';

class About1 extends Component {
	
	constructor () {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal () {
		this.setState({isOpen: true})
	}
	
	render(){
		return (
			<>
				<Header/>
				
				<div className="page-content bg-white">
					<div className="page-banner ovpr-dark overlay-dotted ovdt2 parallax" style={{backgroundImage: "url("+bannerPic1+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">VICO Business</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i>Inicio</Link></li>
										<li>Somos</li>
									</ul>
								</div>
							 </div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
					</div>
					<div className="content-block" id="content-area">
						<div className="section-area section-sp1 bg-gray">
							<div className="container">
								
								<div className="about-head-bx heading-bx wow fadeIn" data-wow-delay="0.3s">
									<div className="head-in">
										<h2 className="title-head m-b0">La empresa</h2>
										<p>VICO SCIENCE es una empresa de base científica-tecnológica de realidad extendida que apoya la transformación digital de organizaciones de diferentes sectores económicos. VICO Business es nuestra área de servicios para las industrias.</p>
									</div>
								</div>
								<div className="section-area bg-gray section-sp1 our-story">
									<div className="container">
										<div className="row align-items-center d-flex">
											<div className="col-lg-5 col-md-12 heading-bx">
												<h2 className="title-head">Nuestra misión</h2>
												<p>Acelerar la productividad de nuestros clientes, creando soluciones tecnológicas que les permitan ahorrar costos, al tiempo que amplían sus capacidades, reemplazando prácticas analógicas e ineficientes con soluciones integrales rentables.</p>
												<p>Desarrollamos soluciones tecnológicas para la comunidad científica desde el estado del arte de la computación espacial para consolidar un ecosistema de I + D + i en VR y otras tecnologías emergentes, enfatizando en la creación de valor para nuestros clientes, y en la mejora continua de nuestros productos y servicios.</p>
											</div>
											<div className="col-lg-7 col-md-12 heading-bx p-lr">
												<div className="video-media-bx">
													<div className="video-bx">
														<img src={aboutPic7} alt=""/>
														<div className="video-media-bx">
															<Link to="https://www.youtube.com/watch?v=iol8n3m88SA" className="popup-youtube video-zoom"><i className="fa fa-play"></i></Link>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="section-area bg-gray section-sp1 our-story">
									<div className="container">
										<div className="row align-items-center d-flex">
											<div className="col-lg-7 col-md-12 heading-bx p-lr">
												<div className="video-media-bx">
													<div className="video-bx">
														<img src={aboutLogo} alt=""/>
													</div>
												</div>
											</div>
											<div className="col-lg-5 col-md-12 heading-bx">
												<h2 className="title-head m-b0">VICO Business</h2>
												<p>Nos enfocamos en tecnologías inmersivas que apuntan a mejorar la capacidad del capital humano de las empresas, mediante realidad virtual o realidad aumentada, reduciendo costos y aumentando la seguridad de los trabajadores; levantamiento y gestión de datos en procesos de producción y faenas logrando una mayor eficiencia de los procesos productivos; optimizando la gestión de conocimiento, y apoyando la ejecución de procesos de análisis críticos.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div className="section-area section-sp3 ovpr-dark bg-fix about-media-bx about-video parallax" style={{backgroundImage: "url("+bgPic2+")"}}>
							<div className="container">
								<div className="row">
									<div className="col-lg-12 wow fadeIn" data-wow-delay="0.3s">
										<div className="video-media-bx">
											<img src={aboutPic3} alt=""/>
											<Link to={"#"} onClick={this.openModal} className="popup-youtube"><i className="fa fa-play"></i></Link>
										</div>
									</div>
								</div>
								<div className="row section-sp1 wow fadeIn" data-wow-delay="0.6s">
									<div className="col-lg-6">
										<div className="row m-t20">
											<div className="col-lg-6 col-md-6 col-sm-6 col-6">
												<div className="counter-style-1 m-b30 m-sm-b15">
													<div className="text-secondry">
														<Count counter={5}/>													
														<span>+</span>
													</div>
													<span className="counter-text">Sectores</span>
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-6 col-6">
												<div className="counter-style-1 m-b30 m-sm-b15">
													<div className="text-secondry">
														<Count counter={10}/>
														<span>+</span>
													</div>
													<span className="counter-text">Millones USD</span>
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-6 col-6">
												<div className="counter-style-1 m-b30">
												<div className="text-secondry">
														<Count counter={8}/>
														<span>+</span>
													</div>
													<span className="counter-text">Años</span>
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-6 col-6">
												<div className="counter-style-1 m-b30">
													<div className="text-secondry">
														<Count counter={65}/>
														<span>+</span>
													</div>
													<span className="counter-text">Proyectos</span>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="text-white">
											<h2 className="m-b10">Asesoramos el uso de la Ley I+D de Incentivo tributario</h2>
											<h5 className="fw5 m-t15">Te ayudamos en el desarrollo del perfil, la formulación y las rendiciones técnicas y financieras.</h5>
											<h5 className="fw5 m-t15">Nuestro equipo ha levantado $10.000.000 USD en financiamiento a proyectos para la innovación.</h5>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						
						<div className="text-center section-sp4">
									<h3 className="title-head m-b20">¿Trabajemos juntos?</h3>
									{/* <Link to={"contact-1"} className="btn button-md radius-xl">Hablemos</Link> */}
									<ContactButton/>
								</div>
					</div>
				</div>
				
				<Footer/>
				
				<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='mGgd40-9gYo' onClose={() => this.setState({isOpen: false})} />
				
			</>
		);
	}
}

export default About1;