import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

// Images
import sliderImg1 from "../../../images/slider/slide1.jpg"
import sliderImg2 from "../../../images/slider/slide2.jpg"
import sliderVid1 from "../../../videos/vico_busines_header_3.mp4"

class Slider1 extends Component{
	
	render(){
		
		const settings = {
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
		};
		
		return(
			<>
				
				<Slider {...settings} className="tt-slider slider-one owl-btn-1 slider-sp0">
					<div className="slider-item">
						<div className="slider-thumb ovbl-middle">
							{/* <img src={sliderImg1} alt=""/> */}
							<video autoPlay loop muted>
								<source src={sliderVid1} type="video/mp4"/>
							</video>
						</div>
						<div className="slider-content text-white">
							<div className="container">
								<div className="content-inner">
									<h6 className="sub-title">VICO Business.</h6>
									<h2 className="title">Ciencia aplicada<br/> a la industria</h2>
									<Link className="btn button-md radius-xl" to={"/quienes-somos"}>Ver más</Link>
								</div>
							</div>
						</div>
					</div>			
				</Slider>
			</>
		);
	}
}

export default Slider1;
