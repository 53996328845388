import React, { Component } from 'react';
import {Link } from 'react-router-dom';
import {Link as ScrollTo} from 'react-scroll';
import Iframe from 'react-iframe';

// Layout
import Header from "../../layout/header/header";
import Footer from "../../layout/footer/footer_contacto";

// Images
import bannerPic1 from '../../../images/banner/banner1.jpg';
import pattern1 from '../../../images/pattern/pt1.png';

class Gracias extends Component {
	
	render(){
		return (
			<>
				
				<Header/>
				
				<div className="page-content bg-white">
					<div className="page-banner ovpr-dark overlay-dotted ovdt1 parallax" style={{backgroundImage: "url("+bannerPic1+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">Contact</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i>Inicio</Link></li>
										<li>Contacto</li>
									</ul>
								</div>
							 </div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
					</div>
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp1" style={{backgroundImage: "url("+pattern1+")"}}>
							<div className="container">
								<div className="heading-bx m-sm-t20">
									<h2 className="title-head m-b0">Contactanos <br/>a través de nuestros canales</h2>
								</div>
								<div className="row">
									<div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-delay="0.3s">
										<div className="icon-box">
											<h5 className="title"><i className="ti-map-alt"></i>Oficina</h5>		
											<p>General del Canto 50 oficina 301, Providencia Santiago, Chile</p>
										</div>
									</div>
									
									<div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-delay="0.9s">
										<div className="icon-box">
											<h5 className="title"><i className="ti-world "></i>Síguenos</h5>		
											<ul className="list-inline ft-social-bx">
												<li><a href={"https://twitter.com/ScienceVico"} className="btn button-sm"><i className="fa fa-twitter"></i></a></li>
												<li><a href={"https://www.linkedin.com/company/vico-science/"} className="btn button-sm"><i className="fa fa-linkedin"></i></a></li>
												<li><a href={"https://www.youtube.com/channel/UCll_R-7Xs6zxbvKjqD8fXpA"} className="btn button-sm"><i className="fa fa-youtube"></i></a></li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div className="section-area section-sp2" style={{backgroundImage: "url("+pattern1+")"}}>
							<div className="container">		
								<div className="row">
									<div className="col-lg-12 col-md-12 wow fadeIn" data-wow-delay="0.4s">
										<form className="contact-bx ajax-form" action="https://formsubmit.co/7b86bd032be58a42c3b256f6d1963cef" method="POST">
											<input type="hidden" name="_subject" value="Correo desde vicobusiness.com"/>	
											<input type="hidden" name="_next" value="Correo desde vicobusiness.com"/>	
											<div className="ajax-message"></div>
											<div className="heading-bx">
												<h2 className="title-head m-b0">Gracias <br/>estarémos en contatco</h2>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					
					</div>
				</div>
				
				<Footer/>
				
			</>
		);
	}
}

export default Gracias;